import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import { graphql } from "gatsby";
import logo from "/src/images/iso_color.png";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const Warranty = ({ data, location }) => {
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  if (isBrowser) {
    window.addEventListener("scroll", reveal);
  }

  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Warranty | Custom Surgical" />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "1200px",
          paddingTop: "4em",
          marginBottom: "2em",
        }}
      >
        <div className="container3" id="footer_page_flex">
          <div id="footer_page_width1">
            <h1 style={{ marginTop: "0" }}>Warranty</h1>
            <p style={{ color: "#9c9c9c" }}>Last updated June 15, 2021</p>
          </div>
          <div id="footer_page_width2">
            <p>
              We want to make sure you're satisfied with your purchase.
              Therefore, we allow you to return your product during the first 14
              days after you received it and to claim a full refund of the
              product cost after delivery - no questions asked (although we
              appreciate it if you could share your feedback).
            </p>
            <p>
              We provide a 2-year warranty for MicroREC to ensure that our
              products don't have material or production defects. All
              manufacturing issues reported within 2 years after delivery will
              entitle for a replacement or a fix at no extra cost.
            </p>
            <p>
              This warranty, however, doesn't cover damage or defects caused by
              misuse, neglect, accidental damage, failure to follow operating
              instructions, alteration, modification or repair of the product,
              included that caused by authorised workshops or persons. This
              warranty also doesn't cover the damage caused by wars, natural
              diseases, terorrism or extreme conditions, and Custom Surgical
              leaves it up to their decision whether to cover such claims for
              reparation or replacements.
            </p>
            <p>
              Warranty covers all products purchased on our website or through
              official distributors. We cannot, however, apply the warranty to
              products purchased from unauthorized third parties or resellers.
            </p>
            <p>The warranty is the subject to the law of Germany.</p>
            <h1 className="container3 reveal fade-right">
              How to claim your warranty
            </h1>
            <div className="container3 reveal fade-left">
              Make sure you have the following things before you claim your
              warranty: 1. Warranty activation card 2. Original packaging 3. The
              receipt of your purchase
            </div>
            <h3 className="container3 reveal fade-right">
              If you purchased the product through the authorised distributor
            </h3>
            <p className="container3 reveal fade-left">
              Please contact your distributor and follow their instructions. You
              can ask as about the contact information for the distributor via
              Whatsapp{" "}
              <a
                className="links_post"
                href="https://wa.me/491782064226?text=Hello,%20I%20came%20from%20the%20website,%20and%20I%20want%20to%20know%20more%20about"
              >
                here
              </a>
              .
            </p>
            <h3 className="container3 reveal fade-right">
              If you purchased the product through customsurgical.co
            </h3>
            <p className="container3 reveal fade-left">
              Please contact us at{" "}
              <a className="links_post" href="mailto:support@customsurgical.co">
                support@customsurgical.co
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Warranty;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
